import { render, staticRenderFns } from "./dtuOnlineLog.vue?vue&type=template&id=754f4ad5&scoped=true&"
import script from "./dtuOnlineLog.vue?vue&type=script&lang=js&"
export * from "./dtuOnlineLog.vue?vue&type=script&lang=js&"
import style0 from "./dtuOnlineLog.vue?vue&type=style&index=0&id=754f4ad5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "754f4ad5",
  null
  
)

export default component.exports